<template>
  <div>
    <Navbar title="Researchers' Resources" :hideSearch="true" />
    <div class="app-body my-5 py-5">
      <div>
        <div class="columns is-vcentered">
          <div class="column is-3">
            <b-field>
              <b-input
                placeholder="Search..."
                type="search"
                v-model="searchQuery"
                @input="filterItems"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <div class="columns is-vcentered">
                <div class="column is-narrow bold">Search by:</div>
                <div class="column">
                  <b-radio
                    class="mt-1 pl-2"
                    v-for="(columnData, index) in tableProps.columnData"
                    :key="index"
                    v-model="searchType"
                    name="searchType"
                    :native-value="columnData"
                    @input="filterItems"
                  >
                    {{ tableProps.columnTitle[index] }}
                  </b-radio>
                </div>
              </div>
            </b-field>
          </div>
        </div>
        <hr />
        <b-table
          :data="filteredItems"
          :loading="tableProps.isLoading"
          :striped="tableProps.isStriped"
          :total="tableProps.total"
          :per-page="tableProps.perPage"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          paginated
        >
          <b-table-column
            v-for="(columnData, index) in tableProps.columnData"
            :key="index"
            :field="columnData"
            :label="tableProps.columnTitle[index]"
            sortable
            v-slot="props"
          >
            {{
              Array.isArray(props.row[columnData])
                ? props.row[columnData].join(", ")
                : props.row[columnData] | filter
            }}
          </b-table-column>

          <b-table-column
            field="actions"
            label="Actions"
            sortable
            v-slot="props"
          >
            <span
              class="has-text-success pointer-active"
              @click="openViewDetailModal(props.row)"
            >
              <i class="mdi mdi-eye pr-1"></i> View
            </span>
          </b-table-column>
        </b-table>
        <!-- Modals Start -->
        <b-modal v-model="modals.showViewDetailModal" has-modal-card>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">External Resource Details</p>
            </header>
            <section v-if="itemDetail" class="modal-card-body">
              <div class="columns">
                <div class="column is-6">
                  <b-field
                    v-for="(item, index) in modals.viewModalTitlesData"
                    :key="index"
                    :label="modals.viewModalTitles[index]"
                  >
                    {{
                      Array.isArray(itemDetail[item])
                        ? itemDetail[item].join(", ")
                        : itemDetail[item]
                    }}
                  </b-field>
                </div>
                <div class="column is-6 has-text-centered mr-4">
                  <img
                    v-if="itemDetail.coverPhotoURL"
                    :src="itemDetail.coverPhotoURL"
                  />
                  <div v-else class="my-5">
                    <i class="mdi mdi-information-outline pr-1"></i> No cover
                    uploaded
                  </div>
                  <div class="mt-2">
                    <a
                      v-if="itemDetail.attachmentURL"
                      :href="itemDetail.attachmentURL"
                    >
                      <b-button type="is-info" icon-left="download">
                        Download External Resource
                      </b-button>
                    </a>
                    <a v-else-if="itemDetail.attachmentURL === null">
                      <b-button type="is-info" icon-left="download" disabled>
                        No file uploaded
                      </b-button>
                    </a>
                    <a v-else>
                      <b-button type="is-info" icon-left="download" disabled>
                        Loading...
                      </b-button>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button label="Close" @click="closeViewDetailModal" />
            </footer>
          </div>
        </b-modal>
        <!-- Modals End -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import { orderBy, filter } from "lodash";

export default {
  name: "Researchers",
  components: { Navbar, Footer },
  data() {
    return {
      isLoading: false,
      category: "image",
      sort: "title-asc",
      sortName: "Title (A-Z)",
      showSideMenu: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        perPage: 5
      },
      // external resources view variables
      externalResourceType: "JSTOR",
      filteredItems: undefined,
      searchQuery: undefined,
      searchType: "title",
      tableProps: {
        columnTitle: [
          "Type",
          "Title",
          "Subject",
          "Author(s)",
          "Publication Year"
        ],
        columnData: ["type", "title", "subject", "authors", "publicationYear"],
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      },
      modals: {
        showViewDetailModal: false,
        showDeleteItemModal: false,
        viewModalTitles: [
          "Type",
          "Title",
          "Subject",
          "Description",
          "Keywords",
          "Authors",
          "Publication Year",
          "Source URL"
        ],
        viewModalTitlesData: [
          "type",
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "sourceURL"
        ]
      },
      itemDetail: undefined
    };
  },
  computed: {
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    },
    items() {
      return orderBy(
        this.$store.state.externalResource,
        ["dateCreated"],
        ["desc"]
      );
    }
  },
  watch: {
    items: function (val) {
      this.filteredItems = val;
    }
  },
  async created() {
    if (this.$route.query.externalResourceType) {
      this.externalResourceType =
        this.$route.query.externalResourceType.toString();
    }
    this.tableProps.isLoading = true;
    await this.$store.dispatch("getExternalResource");
    this.filteredItems = this.items;
    this.tableProps.isLoading = false;
    this.filterItems();
  },
  methods: {
    openViewDetailModal(selectedItem) {
      this.itemDetail = selectedItem;
      this.modals.showViewDetailModal = true;
    },
    closeViewDetailModal() {
      this.modals.showViewDetailModal = false;
    },
    filterItems() {
      if (this.searchQuery === "") {
        this.filteredItems = this.items;
      } else {
        this.filteredItems = filter(this.items, (item) => {
          return item[this.searchType]
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.box-anim:hover {
  transition: transform 125ms;
  transform: scale(1.05);
  -webkit-box-shadow: 0 0.5em 1em -0.125em #808080;
  box-shadow: 0 0.5em 1em -0.125em #808080;
}
</style>
